import { Avatar, Button, Image, Layout } from "antd";

import React from "react";
import hero from "../Assets/placeholder.jpg";
import logo from "../Assets/logo_s_white.png";
import { useLocation } from "react-router-dom";

const { Header, Content } = Layout;

export default function LandingPage({ history }) {
  return (
    <Layout>
      <Header className="landing-header">
        <Avatar shape="square" src={logo} />
        <Button type="primary" href="/auth" onClick={() => history.push("/auth")}>
          Log In
        </Button>
      </Header>
      <div className="landing-heroContainer">
        <Image preview={false} width="100%" height="40vh" src={hero} />
        <div className="landing-heroTextContainer">
          <h1 className="landing-hero-title">
            <span className="red">Set</span>
            <span>Stream</span>
          </h1>
          <h3 className="landing-h3 landing-hero-subtitle">
            Production-grade live streaming for film and television.
          </h3>
        </div>
      </div>
      <Layout className="landing-layout">
        <Content className="landing-content">
          <p className="landing-p">
            SetStream is a live video streaming service designed for film and television
            production. Avoid the crowded video village and seamlessly send live video
            from your production set or studio directly to the people who need to see it,
            anywhere in the world.
          </p>

          <CallToAction title="Get Started" href="#contact-us" />

          <ul>
            {bulletList.map((bullet, idx) => (
              <li key={idx}>
                <p className="landing-p"> {bullet.text}</p>
              </li>
            ))}
          </ul>

          <Features features={features} />
          <Contact />
        </Content>
      </Layout>
    </Layout>
  );
}

function CallToAction({ title, href }) {
  return (
    <div className="row flex-center landing-vertical-spacing landing-section-header">
      <a href={href}>
        <h2 className="landing-h2 mb0 landing-cta">
          <span className="landing-bracket black">[</span>
          {title}
          <span className="landing-bracket black">]</span>
        </h2>
      </a>
    </div>
  );
}

function SectionHeader({ title }) {
  return (
    <div className="row flex-center landing-vertical-spacing landing-section-header">
      <h2 className="landing-h2 mb0">
        <span className="landing-bracket red">[</span>
        {title}
        <span className="landing-bracket red">]</span>
      </h2>
    </div>
  );
}

function Features({ features }) {
  return features.map((feature, idx) => (
    <div key={idx}>
      <SectionHeader title={feature.title} />
      <p className="landing-p">{feature.text}</p>
    </div>
  ));
}

function Contact() {
  const location = useLocation();
  return (
    <div className="column" id="form">
      {location.hash.includes("submitted") ? <PostSent /> : <PreSent />}
    </div>
  );
}

function PreSent() {
  const formProps = window.location.host.includes("localhost")
    ? {}
    : {
        name: "contact",
        method: "POST",
        "data-netlify": "true",
        "data-netlify-honeypot": "bot-field",
      };
  return (
    <div className="column flex-center landing-vertical-spacing">
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
      <a id="contact-us" />
      <h2 className="landing-h2">Want to try SetStream?</h2>
      <p className="landing-p mb0">Contact us and we’ll be in touch.</p>

      <form className="column" action="#submitted" {...formProps}>
        <input className="landing-input" type="hidden" aria-label="bots" />
        <input
          className="landing-input"
          type="hidden"
          value="contact"
          aria-label="formname"
        />
        <div className="flex-center column">
          <div className="column landing-vertical-spacing">
            <input
              className="landing-input"
              id="name"
              type="text"
              aria-label="Name"
              placeholder="Name*"
              required
            />
          </div>

          <div className="column">
            <input
              className="landing-input"
              id="email"
              type="email"
              placeholder="Email*"
              aria-label="Email"
              required
            />
          </div>

          <div className="column landing-vertical-spacing">
            <input
              className="landing-input"
              placeholder="Role or Title"
              id="role"
              type="text"
              aria-label="Role"
            />
          </div>
          <input
            className="landing-btn"
            id="input-submit"
            type="submit"
            aria-label="Submit"
            value="Submit"
          />
        </div>
      </form>
    </div>
  );
}

function PostSent() {
  return (
    <div className="column landing-vertical-spacing">
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
      <a id="submitted" />
      <h2 className="landing-h2">Thanks! We&rsquo;ll be in touch soon.</h2>
    </div>
  );
}

const bulletList = [
  {
    text:
      "Optimized for ultra-high-quality, ultra-low-latency private live streaming, SetStream makes it simple to deliver the action to your production teams and crew wherever they are in the world.",
  },
  {
    text:
      "With a secure web-based player that works on virtually any device, viewers can watch live streams on their phones, tablets or computers, on any network.",
  },
  {
    text:
      "Best-in-class privacy and security controls ensure that your sensitive live stream content is only seen by the people who are supposed to see it.",
  },
  {
    text:
      "Industry-standard protocol support means on-set technicians can send live streams from the hardware and software streaming encoders that they’re already using.",
  },
  {
    text:
      "SetStream’s straightforward usage-based pricing removes surprises and makes budgeting predictable.",
  },
];

const features = [
  {
    title: "Ultra-quality Video",
    text:
      "SetStream can receive, transcode and deliver live video streams at 1080p Full HD quality at 60 frames per seconds and bitrates as high as 8.5Mbps. On ingest, our cloud-based transcoding pipeline automatically generates a range of output qualities and resolutions to deliver adaptive bitrate (ABR) streams. Our highly-optimized video player ensures that viewers across different device types and network conditions automatically receive the best quality stream possible.",
  },
  {
    title: "Private by Design",
    text:
      "Live streams are only viewable by authenticated and authorized users, and streaming content cannot be made public or streamed outside of the SetStream browser player. All viewer access to streaming content is tracked and monitored, and viewer privileges are auditable and straightforward to manage. Live video content is never stored in SetStream.",
  },
  {
    title: "Secure Content Delivery",
    text:
      "SetStream provides a secure encrypted streaming experience for your sensitive content. Our content ingestion pipeline uses RTMP over TLS, the most common secure protocol used in streaming software and hardware. Delivered video streams are served from our global content delivery network securely via TLS, and are cryptographically restricted for viewing only in the SetStream browser player. Live video content from SetStream cannot be downloaded and it cannot be played in standalone video players.",
  },
  {
    title: "Goodbye Latency",
    text:
      "Our cloud-based content pipeline and global delivery network are highly tuned for ultra-low latency streaming. With intelligent network pathing, encoded streams are automatically ingested by SetStream at the optimal network edge location. When combined with our browser player’s intelligent bitrate switching and segment chunking algorithms, live streams can be delivered to viewers with glass-to-glass latency averaging 5 seconds, and as low as 2-3 seconds.",
  },
  {
    title: "Seamless Viewer Experience",
    text:
      "Live streams can be viewed in SetStream’s web-based secure player from any device that has a modern web browser, including iOS and Android devices. There are no apps to install or hardware to configure, and our adaptive bitrate streaming optimizes for the highest quality streaming experience.",
  },
  {
    title: "Choose your own Encoder",
    text:
      "SetStream supports robust and secure industry-standard streaming protocols, and works with the streaming devices and software that you already have. Technicians can automatically start a live stream in seconds from any encoder that supports RTMPS streaming with H.264 video and AAC (LC) audio codecs. SetStream works with a broad range of popular encoders, including hardware devices from Teradek, AJA and NewTek, and software including OBS, vMix and Wirecast.",
  },
  {
    title: "Simple Pricing",
    text:
      "SetStream pricing is simple and straightforward. There are no per-viewer charges, and all streams are billed at the same rate regardless of the number of concurrent viewers. Our pay-as-you go pricing model is based on active streaming minutes, and keeps costs predictable to ease your planning and budgeting.",
  },
];
