import React from "react";
import config from "../config";

export default function VideoPlayer({ className, controls, url, width, height }) {
  const videoRef = React.useRef();
  React.useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    const { player, cleanups } = createPlayer(videoRef.current);
    if (url) {
      player.setAutoplay(true);
      player.load(url);
    }
    return () => {
      player.pause();
      cleanups.forEach((cb) => cb());
      player.delete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current, url]);
  const sty = {
    position: "absolute",
    top: 0,
    left: 0,
  };
  if (width) {
    sty.width = width;
  }
  if (height) {
    sty.height = height;
  }
  return (
    <video
      ref={videoRef}
      playsInline
      controls={controls}
      className={className}
      style={sty}
    />
  );
}

function createPlayer(element) {
  const {
    create,
    ErrorType,
    isPlayerSupported,
    PlayerEventType,
    PlayerState,
  } = window.IVSPlayer;

  if (!isPlayerSupported) {
    throw new Error("IVS Player is not supported in this browser");
  }

  const player = create();
  player.attachHTMLVideoElement(element);

  const result = { player, cleanups: [] };
  function setupEventListener(k, cb) {
    player.addEventListener(k, cb);
    result.cleanups.push(() => player.removeEventListener(k, cb));
  }

  if (config.videoDebug) {
    for (let state of Object.values(PlayerState)) {
      setupEventListener(state, (d) => {
        console.debug(`PlayerState.${state}:`, d);
      });
    }
    for (let state of Object.values(PlayerEventType)) {
      setupEventListener(state, (d) => {
        console.debug(`PlayerEventType.${state}:`, d);
      });
    }
  }

  setupEventListener(PlayerEventType.ERROR, (error) => {
    const statusTooManyRequests = 429;
    if (error.type === ErrorType.NOT_AVAILABLE && error.code === statusTooManyRequests) {
      console.error("Concurrent-viewer limit reached", error);
    } else {
      console.error("ERROR", error);
    }
  });

  return result;
}
