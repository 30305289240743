import React from "react";

export default function useUniqueId() {
  const [id, setId] = React.useState(newUniqueId());
  function cycle() {
    setId(newUniqueId);
  }
  return [id, cycle];
}

function newUniqueId() {
  return Date.now() + "-" + Math.random();
}
