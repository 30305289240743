import { AuthState } from "@aws-amplify/ui-components";
import React from "react";
import _ from "lodash";
import api from "../api";
import { useApiCallCollection } from "./useApiCall";
import useRefresher from "./useRefresher";
import { useUser } from "./useUser";

const StreamsContext = React.createContext({});

export function useStreams() {
  return React.useContext(StreamsContext);
}

export function StreamsProvider({ children }) {
  const apiCall = useApiCallCollection("streams", api.getStreams, {
    memcache: true,
    cycleUniqueId: "change",
  });
  const { authState, user } = useUser();
  useRefresher(
    () => {
      if (authState === AuthState.SignedIn && user) {
        return apiCall.streamsBust();
      } else {
        return Promise.resolve();
      }
    },
    [authState, user],
    { timeout: 10000, immediate: true }
  );

  const [onlineStreams, offlineStreams] = _.partition(apiCall.streams, "isLive");
  function findStream({ slug }) {
    return apiCall.streams.find((s) => s.slug === slug) || {};
  }
  return (
    <StreamsContext.Provider
      value={{ ...apiCall, onlineStreams, offlineStreams, findStream }}
    >
      {children}
    </StreamsContext.Provider>
  );
}
