import { ClockCircleOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import React from "react";
import TextWithIcon from "./TextWithIcon";

export default function StreamTimestamp({ stream }) {
  let text;
  if (stream.isLive) {
    text = (
      <span>
        Live for{" "}
        <Moment fromNow ago>
          {stream.lastStarted}
        </Moment>
      </span>
    );
  } else if (stream.lastStopped) {
    text = (
      <span>
        Offline for{" "}
        <Moment fromNow ago>
          {stream.lastStopped}
        </Moment>
      </span>
    );
  } else {
    text = "Offline";
  }
  return <TextWithIcon icon={ClockCircleOutlined} text={text} />;
}
