import queryString from "./queryString";

export function followParam({ location, history, param }) {
  const params = queryString.parse(location.search);
  const next = params[param];
  if (!next) {
    return false;
  }
  history.push(next);
  return true;
}

export function locationAsParam({ location, newPath, param }) {
  const existingUrl = location.pathname + location.search + location.hash;
  const search = queryString.stringify({ [param]: existingUrl });
  return newPath + "?" + search;
}
