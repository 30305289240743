import React from "react";
import { Tag } from "antd";
export default function LiveTag({ stream }) {
  return stream?.isLive ? (
    <Tag key="live" color="red">
      Live
    </Tag>
  ) : (
    <Tag key="offline">Offline</Tag>
  );
}
