const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id: "us-west-2:79a3ae32-df1e-4000-b908-bd1571f1bb11",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_vYMO9y10E",
  aws_user_pools_web_client_id: "3pa0p6a6urtvgdie2qtuhc2j5n",
  oauth: {},
};

export default awsmobile;
