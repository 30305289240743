import {
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  VideoCameraFilled,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { Auth } from "@aws-amplify/auth";
import React from "react";
import { useStreams } from "../hooks/useStreams";
import { useUser } from "../hooks/useUser";

const { Sider } = Layout;

export default function SideNav() {
  const { streams, onlineStreams, offlineStreams } = useStreams();
  const { user } = useUser();
  const location = useLocation();

  async function onSignOutClick() {
    await Auth.signOut();
  }

  const spacer = <div style={{ minHeight: "4vh" }} />;
  const onlineStreamItems = onlineStreams.map((s) => streamMenuItem("/watch/", s));
  const offlineStreamItems = offlineStreams.map((s) => streamMenuItem("/watch/", s));
  const editableStreamItems = streams
    .filter((s) => s.userRole === "editor")
    .map((s) => streamMenuItem("/manage/", s));

  return (
    <Sider breakpoint="md" collapsedWidth="0" width="250">
      <div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          defaultOpenKeys={["online", "offline", "editable"]}
        >
          {spacer}
          <div className="flex-center bold unselectable">
            <span className="red">[</span>set.stream
            <span className="red">]</span>
          </div>
          {spacer}
          <Menu.Item key="/dashboard" icon={<HomeOutlined />}>
            Dashboard
            <Link to="/dashboard" />
          </Menu.Item>
          {spacer}
          {onlineStreamItems.length === 0 ? (
            <Menu.Item icon={<VideoCameraFilled className="red" />}>
              No Live Streams
            </Menu.Item>
          ) : (
            <Menu.SubMenu
              key="online"
              title="Live Streams"
              icon={<VideoCameraFilled className="red" />}
            >
              {onlineStreamItems}
            </Menu.SubMenu>
          )}
          {spacer}
          {offlineStreamItems.length === 0 ? (
            <Menu.Item icon={<VideoCameraOutlined />}>No Offline Streams</Menu.Item>
          ) : (
            <Menu.SubMenu
              key="offline"
              title="Offline Streams"
              icon={<VideoCameraOutlined />}
            >
              {offlineStreamItems}
            </Menu.SubMenu>
          )}
          {spacer}
          {editableStreamItems.length > 0 && (
            <>
              <Menu.SubMenu
                key="editable"
                title="Manage Streams"
                icon={<SettingOutlined />}
              >
                {editableStreamItems}
              </Menu.SubMenu>
              {spacer}
            </>
          )}
          <Menu.Item key="/profile" icon={<UserOutlined />}>
            {user && user.attributes.name}
          </Menu.Item>
          <Menu.Item key="signout" icon={<LogoutOutlined />} onClick={onSignOutClick}>
            Sign Out
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
}

function streamMenuItem(root, stream) {
  const url = `${root}${stream.slug}`;
  return (
    <Menu.Item key={url}>
      {stream.title}
      <Link to={url} />
    </Menu.Item>
  );
}
