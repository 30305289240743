import { Button, Card, Collapse, ConfigProvider, List, PageHeader, Tag } from "antd";

import { AppContent } from "../components/Layouts";
import { Link } from "react-router-dom";
import React from "react";
import StreamTimestamp from "../components/StreamTimestamp";
import { VideoCameraOutlined } from "@ant-design/icons";
import { useStreams } from "../hooks/useStreams";

const { Panel } = Collapse;

const noOnlineStreams = () => (
  <div style={{ textAlign: "center" }}>
    <h4>All streams are offline</h4>
  </div>
);

const noOfflineStreams = () => <p />;

export default function DashboardPage() {
  const { onlineStreams, offlineStreams } = useStreams();
  return (
    <AppContent>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        backIcon={<VideoCameraOutlined />}
        title="Streaming Now"
      />
      <Collapse ghost defaultActiveKey={["live-streams"]}>
        <Panel header="Live streams" key="live-streams">
          <ConfigProvider renderEmpty={noOnlineStreams}>
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={onlineStreams}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    title={
                      <span>
                        {item.title}&nbsp;&nbsp;
                        <Tag key="live" color="red">
                          Live
                        </Tag>
                      </span>
                    }
                    extra={
                      <Link key={`/watch/${item.slug}`} to={`/watch/${item.slug}`}>
                        <Button key={`/watch/${item.slug}`} type="primary">
                          Watch
                        </Button>
                      </Link>
                    }
                    actions={[<StreamTimestamp key="timestamp" stream={item} />]}
                  >
                    {item.description}
                  </Card>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </Panel>

        <Panel header="Offline streams" key="offline-streams">
          <ConfigProvider renderEmpty={noOfflineStreams}>
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={offlineStreams}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    title={
                      <span>
                        {item.title}&nbsp;&nbsp;
                        <Tag key="offline">Offline</Tag>
                      </span>
                    }
                    extra={
                      <Link key={`/watch/${item.slug}`} to={`/watch/${item.slug}`}>
                        <Button key={`/watch/${item.slug}`} type="default">
                          Watch
                        </Button>
                      </Link>
                    }
                  >
                    {item.description}
                  </Card>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </Panel>
      </Collapse>
    </AppContent>
  );
}
