import { Layout } from "antd";
import React from "react";
const { Content } = Layout;

export function AppContent({ children }) {
  return (
    <Content style={{ margin: "8px 16px", paddingLeft: "4%", paddingRight: "4%" }}>
      {children}
    </Content>
  );
}
