import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { followParam, locationAsParam } from "../modules/followNext";

import AuthPage from "../pages/AuthPage";
import { AuthState } from "@aws-amplify/ui-components";
import DashboardPage from "../pages/DashboardPage";
import LandingPage from "../pages/LandingPage";
import { Layout } from "antd";
import ManageStreamPage from "../pages/ManageStreamPage";
import React from "react";
import SideNav from "./SideNav";
import WatchStreamPage from "../pages/WatchStreamPage";
import { useUser } from "../hooks/useUser";

const { Footer } = Layout;

export default function Router() {
  return (
    <BrowserRouter>
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          <Route exact path="/" component={null} />
          <Route path="/auth" component={null} />
          <Route path="/" component={SideNav} />
        </Switch>
        <Layout className="site-layout">
          <Switch>
            <Route exact path="/" component={requireAuth(IGNORE_AUTH)(LandingPage)} />
            <Route
              exact
              path="/auth"
              component={requireAuth(ACCESS_ONLY_FOR_UNAUTHED)(AuthPage)}
            />
            <Route
              exact
              path="/dashboard"
              component={requireAuth(ACCESS_ONLY_FOR_AUTH)(DashboardPage)}
            />
            <Route
              exact
              path="/watch/:slug"
              component={requireAuth(ACCESS_ONLY_FOR_AUTH)(WatchStreamPage)}
            />
            <Route
              exact
              path="/manage/:slug"
              component={requireAuth(ACCESS_ONLY_FOR_AUTH)(ManageStreamPage)}
            />
          </Switch>
          <Footer style={{ textAlign: "center" }}>set.stream &copy; 2020</Footer>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
}

const ACCESS_ONLY_FOR_AUTH = "mustauth";
const ACCESS_ONLY_FOR_UNAUTHED = "mustnotauth";
const IGNORE_AUTH = "ignoreauth";

function requireAuth(authSpec) {
  return function (Wrapped) {
    return function (props) {
      const { authState, user } = useUser();
      const history = useHistory();
      const location = useLocation();
      const [doRender, setDoRender] = React.useState(false);

      React.useEffect(() => {
        if (authSpec === IGNORE_AUTH) {
          setDoRender(true);
          return;
        }
        if (authState === AuthState.Loading) {
          return;
        }
        const authed = Boolean(user);
        if (authed && authSpec === ACCESS_ONLY_FOR_AUTH) {
          setDoRender(true);
        } else if (authed && authSpec === ACCESS_ONLY_FOR_UNAUTHED) {
          if (!followParam({ location, history, param: "next" })) {
            history.push("/dashboard");
          }
        } else if (!authed && authSpec === ACCESS_ONLY_FOR_AUTH) {
          history.push(locationAsParam({ location, newPath: "/auth", param: "next" }));
        } else {
          setDoRender(true);
        }
      }, [history, authState, user, location]);
      if (!doRender) {
        return null;
      }
      return <Wrapped {...props} />;
    };
  };
}
