import { Divider, PageHeader } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { AppContent } from "../components/Layouts";
import LiveTag from "../components/LiveTag";
import React from "react";
import StreamTimestamp from "../components/StreamTimestamp";
import VideoPlayer from "../components/VideoPlayer";
import api from "../api";
import { useApiCall } from "../hooks/useApiCall";
import useRefresher from "../hooks/useRefresher";
import withKey from "../modules/withKey";

function WatchStreamPage() {
  const params = useParams();
  const history = useHistory();
  const { stream, streamBust } = useApiCall(
    "stream",
    () => api.getStream({ slug: params.slug }),
    { cycleUniqueId: "etag" }
  );
  useRefresher(() => streamBust(), [streamBust], { timeout: 5000 });

  const [playingUrl, setPlayingUrl] = React.useState();
  const [playingStreamId, setPlayingStreamId] = React.useState();
  React.useEffect(() => {
    if (!playingUrl || playingStreamId !== stream.id) {
      setPlayingStreamId(stream.id);
      setPlayingUrl(stream.playbackUrl);
    }
  }, [playingStreamId, playingUrl, stream]);
  return (
    <AppContent>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/dashboard")}
        title={stream.title}
        tags={[<LiveTag key="live" stream={stream} />]}
        footer={stream.description}
        extra={[<StreamTimestamp key="timestamp" stream={stream} />]}
      />
      <Divider />
      <div style={containerStyle}>
        <VideoPlayer
          key={stream.isLive + playingUrl}
          url={stream.isLive && playingUrl}
          controls
          width="100%"
          height="100%"
        />
      </div>
    </AppContent>
  );
}

export default withKey((props) => props.match.params.slug)(WatchStreamPage);

const containerStyle = {
  position: "relative",
  paddingTop: "56.25%", // Player ratio: 100 / (1280 / 720)
};
