import React from "react";

const SENTINEL = Symbol("useRefFnSentinel");

/**
 * Returns a ref that invokes 'init' just the first time the hook is run.
 * Can be used to run an expensive function just once,
 * since useRef does not take a callback like setState.
 * See https://github.com/facebook/react/issues/14490
 * for more background.
 */
export default function useRefFn(init) {
  const ref = React.useRef(SENTINEL);
  if (ref.current === SENTINEL) {
    ref.current = init();
  }
  return ref;
}
