import { Button, Col, Form, Input, Row } from "antd";
import { Divider, PageHeader, Select, Space, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { AppContent } from "../components/Layouts";
import LiveTag from "../components/LiveTag";
import React from "react";
import _ from "lodash";
import api from "../api";
import { useApiCallMutable } from "../hooks/useApiCall";
import { useParams } from "react-router-dom";
import withKey from "../modules/withKey";

const { Option } = Select;
const { Paragraph } = Typography;

function ManageStreamPage() {
  const params = useParams();
  const { stream, streamLoading, streamApply } = useApiCallMutable(
    "stream",
    () => api.getStreamDetails({ slug: params.slug }),
    {
      update: api.updateStreamDetails,
    }
  );
  const {
    permissions,
    permissionsLoading,
    permissionsApply,
  } = useApiCallMutable(
    "permissions",
    () => api.getStreamPermissions({ slug: params.slug }),
    { update: api.replaceStreamPermissions }
  );

  function updateStream(values) {
    return streamApply({ slug: params.slug, ...values });
  }

  function updatePermissions(values) {
    permissionsApply({ slug: params.slug, permissions: values.permissions });
  }

  return (
    <AppContent>
      <PageHeader
        className="site-page-header"
        title={stream.title}
        tags={<LiveTag stream={stream} />}
      />
      {!streamLoading && <StreamForm stream={stream} onFinish={updateStream} />}
      <Divider />
      {!permissionsLoading && (
        <PermissionsForm
          permissions={permissions.items || []}
          onFinish={updatePermissions}
        />
      )}
    </AppContent>
  );
}

export default withKey((props) => props.match.params.slug)(ManageStreamPage);

function StreamForm({ stream, onFinish }) {
  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ title: stream.title, description: stream.description }}
      onFinish={onFinish}
    >
      <div>
        <ReadonlyRow
          label="Owner:"
          value={<Paragraph copyable>{stream.ownerEmail}</Paragraph>}
        />
        <ReadonlyRow
          label="Ingest Server:"
          value={<Paragraph copyable>{stream.ingestServer}</Paragraph>}
        />
        <ReadonlyRow
          label="Stream Key:"
          value={<Paragraph copyable>{stream.streamKey}</Paragraph>}
        />
      </div>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Title is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

function ReadonlyRow({ stream, label, value }) {
  return (
    <Row>
      <Col {...layout.labelCol}>{label}</Col>
      <Col {...layout.wrapperCol}>{value}</Col>
    </Row>
  );
}

const roles = [
  { label: "Viewer", value: "viewer" },
  { label: "Editor", value: "editor" },
];

function PermissionsForm({ permissions, onFinish }) {
  const [form] = Form.useForm();

  return (
    <>
      <Typography.Title level={5}>Permissions</Typography.Title>
      <Form
        name="permissionsform"
        form={form}
        onFinish={onFinish}
        initialValues={{ permissions }}
      >
        <Form.List name="permissions">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key}>
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      {...field}
                      wrapperCol={{}}
                      labelCol={{}}
                      label="Email"
                      name={[field.name, "email"]}
                      fieldKey={[field.fieldKey, "email"]}
                      rules={[{ required: true, message: "Missing email" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        !_.isEqual(prevValues, curValues)
                      }
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          wrapperCol={{}}
                          labelCol={{}}
                          label="Role"
                          name={[field.name, "role"]}
                          fieldKey={[field.fieldKey, "role"]}
                          rules={[{ required: true, message: "Missing Role" }]}
                        >
                          <Select style={{ width: 130 }}>
                            {roles.map(({ label, value }) => (
                              <Option key={value} value={value}>
                                {label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "50%" }}
                  icon={<PlusOutlined />}
                >
                  Add permission
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

const layout = {
  labelCol: { md: 4, sm: 24 },
  wrapperCol: { md: 20, sm: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
