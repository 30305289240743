import "./index.css";

import App from "./App";
import Promise from "./modules/bluejay";
import React from "react";
import ReactDOM from "react-dom";

// overwrite native Promise implementation with Bluebird's (for axios)
window.Promise = Promise;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
