import { Auth } from "aws-amplify";
import apiBase from "./modules/apiBase";
import config from "./config";

const instance = (function () {
  const inst = apiBase.create(config.apiHost, {
    debug: config.debug,
    chaos: config.chaos,
    withCredentials: false,
  });
  inst.interceptors.request.use((config) => {
    return Auth.currentSession()
      .then((data) => data.getIdToken().getJwtToken())
      .then((jwt) => {
        config.headers.Authorization = jwt;
        return Promise.resolve(config);
      });
  });
  return inst;
})();

const get = (path, params, opts) => {
  return instance.get(path, apiBase.mergeParams(params, opts));
};
const post = (path, params, opts) => {
  return instance.post(path, params, opts);
};

const put = (path, params, opts) => {
  return instance.put(path, params, opts);
};

const del = (path, params, opts) => {
  return instance.delete(path, apiBase.mergeParams(params, opts));
};

const api = {
  get: get,
  post: post,
  put: put,
  delete: del,

  getStreams: (data) => get("/streams", data),
  createStream: (data) => post("/streams", data),
  getStream: ({ slug, ...rest }) => get(`/streams/${slug}`, rest),
  getStreamDetails: ({ slug, ...rest }) => get(`/streams/${slug}/details`, rest),
  updateStreamDetails: ({ slug, ...rest }) => post(`/streams/${slug}/details`, rest),
  getStreamPermissions: ({ slug, ...rest }) => get(`/streams/${slug}/permissions`, rest),
  replaceStreamPermissions: ({ slug, ...rest }) =>
    put(`/streams/${slug}/permissions`, rest),
};

export default { ...apiBase, ...api };
