import { Logger } from "../modules/logger";
import React from "react";
import _ from "lodash";

const logger = new Logger("api.cache");

export const CACHE_KEY_MISSING = Symbol("cache-key-missing");

export const ApiCacheContext = React.createContext({});

/**
 * Hook for a global cache, usually used for invalidation of api calls.
 */
export function useApiCache() {
  return React.useContext(ApiCacheContext);
}

export function ApiCacheProvider({ children }) {
  const cacheRef = React.useRef({});
  function registerBuster(key, reloader) {
    logger.info("registering", key);
    cacheRef.current[key] = reloader;
  }
  function bust(key) {
    const buster = cacheRef.current[key];
    if (buster) {
      logger.info("busting", key);
      return buster() || Promise.resolve(null);
    } else {
      logger.error("key not present", key);
      return Promise.resolve(CACHE_KEY_MISSING);
    }
  }
  function bustAll(keys) {
    const promises = keys
      ? _.map(keys, (k) => bust(k))
      : _.map(cacheRef.current, (buster) => Promise.resolve(buster()));
    logger.info("busting cache, size", promises.length);
    return Promise.all(promises);
  }
  return (
    <ApiCacheContext.Provider value={{ registerBuster, bust, bustAll }}>
      {children}
    </ApiCacheContext.Provider>
  );
}
