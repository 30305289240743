import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export const UserContext = React.createContext();
export const useUser = () => React.useContext(UserContext);
export function UserProvider({ children }) {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        authState,
      }}
    >
      {!authState && <AmplifyAuthenticator />}
      {children}
    </UserContext.Provider>
  );
}
