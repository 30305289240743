import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react";

import React from "react";

export default function Authenticator() {
  return (
    <div>
      <a href="/">
        <h1 className="flex-center bold" style={{ minHeight: "20vh" }}>
          <span className="red">[</span>set.stream
          <span className="red">]</span>
        </h1>
      </a>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            { type: "email", required: true },
            {
              type: "password",
              required: true,
              placeholder: "Enter your password (minimum 8 characters)",
            },
            {
              type: "name",
              required: true,
              label: "Display Name *",
              placeholder: "Enter your display name",
            },
          ]}
        />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
      </AmplifyAuthenticator>
    </div>
  );
}
