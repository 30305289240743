import React from "react";

export const MemoryCacheContext = React.createContext({});

/**
 * Hook for a global cache of any objects.
 * Use when, for example, the same hook is used multiple times
 * and you want to warm up subsequent uses.
 */
export function useMemoryCache() {
  return React.useContext(MemoryCacheContext);
}

export function MemoryCacheProvider({ children }) {
  const cacheRef = React.useRef({});
  function getter(key) {
    return cacheRef.current[key];
  }
  function setter(key, value) {
    cacheRef.current[key] = value;
  }
  function setIfEmpty(key, value) {
    if (!cacheRef.current[key]) {
      cacheRef.current[key] = value;
    }
  }
  function clear() {
    cacheRef.current = {};
  }
  return (
    <MemoryCacheContext.Provider value={{ get: getter, set: setter, setIfEmpty, clear }}>
      {children}
    </MemoryCacheContext.Provider>
  );
}
