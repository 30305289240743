import "./App.less";

import Amplify from "aws-amplify";
import { ApiCacheProvider } from "./hooks/useApiCache";
import { MemoryCacheProvider } from "./hooks/useMemoryCache";
import React from "react";
import Router from "./components/Router";
import { StreamsProvider } from "./hooks/useStreams";
import { UserProvider } from "./hooks/useUser";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

export default function App() {
  return (
    <MemoryCacheProvider>
      <ApiCacheProvider>
        <UserProvider>
          <StreamsProvider>
            <Router />
          </StreamsProvider>
        </UserProvider>
      </ApiCacheProvider>
    </MemoryCacheProvider>
  );
}
