import React from "react";

export default function useRefresher(handler, deps, { timeout, immediate }) {
  React.useEffect(() => {
    const handle = setInterval(handler, timeout);
    return () => {
      clearInterval(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, timeout]);

  React.useEffect(() => {
    if (immediate) {
      handler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
