import { AuthState } from "@aws-amplify/ui-components";
import { Logger } from "../modules/logger";
import PromiseQueue from "../modules/promiseQueue";
import React from "react";
import _ from "lodash";
import api from "../api";
import { mergeDeep } from "immutable";
import { useApiCache } from "./useApiCache";
import { useMemoryCache } from "./useMemoryCache";
import useRefFn from "./useRefFn";
import useUniqueId from "./useUniqueId";
import { useUser } from "./useUser";

const hookLogger = new Logger("api.hook");

export function useApiCall(key, caller, options) {
  const initial = _.get(options, "initial") || {};
  return useApiCallImpl(key, caller, initial, _.identity, options);
}

/**
 * Hook for working with api state.
 * Use key, keyLoading, and keyError to detect when changes are happening.
 * For example, if `key` is 'dashboard', the returned object will have:
 *
 * - `a.dashboard` (the data from the API),
 * - `a.dashboardLoading` (true when an api call is ongoing),
 * - `a.dashboardError` (error during fetch).
 *
 * There are also a couple other fields:
 * - `<key>Bust`: Bust the cache and refresh this api call.
 * - `<key>UniqueId`: Requires **options.cycleUniqueId** to be valid.
 *   Random string that can be set when the data is fetched or changes.
 *   Can be used as the `key` prop to make sure something fully re-renders
 *   after the API call is complete or data changes.
 *   If options.cycleUniqueId is 'fetch', the unique id is cycled every time
 *   new data is fetched. If it is 'change', the unique id is cycled only when
 *   the fetched/stored data changes.
 *
 * == Options
 *
 * @param {String} key Key for the api call.
 *   Key 'dashboard' would give returned object keys of
 *   `dashboard`, `dashboardLoading`, etc.
 * @param {Function} caller Api call, usually the pair call like `api.getCurrentUser`.
 * @param {Object} options
 * @param {*} [options.initial] Initial value for the api call response data.
 * @param {Function} [options.update] See below,
 *   usually something like `api.patchDraftPayment`
 * @param {Function} [options.onFetch] Callback invoked with response body on
 *   successful fetch. Usually you don't need to use this except for side effects.
 * @param {Boolean} [options.allowUnauthed] By default, api calls are only done
 *   when the customer is not unauthed.
 *   Set allowUnauthed to make the call while unauthed as well.
 * @param {Boolean} [options.memcache] If true, pull the initial state from memory,
 *   and store any updates into the global memory cache.
 *   Useful if the same API call is used on multiple pages,
 *   but it doesn't make sense to store it as a context manager,
 *   like if there is no mutable state.
 * @param {string} [options.cycleUniqueId] See UniqueId above.
 * @param {Array} [options.deps] Re-fetch when these deps change (something like a url slug, usually).
 *
 * == Updating via API
 *
 * Pass an 'update' method that can be used to update the instance.
 *
 * Call `<key>Patch({x: y}` to call the API and update fields,
 * and update `<key>` with the returned value.
 *
 * Call `<key>Enqueue(callback)` when you need to use a non-PATCH
 * api call to update the resource. This ensures your callback is only run
 * when any ongoing PATCH promises have resolved.
 * See PromiseQueue.enqueue for more details.
 *
 * There are some caveats and nuances to how concurrent updates work.
 * Read the code comments for more details.
 *
 * == Change and Commit
 *
 * Call `<key>Set({x: y})` to change `<key>` in memory (react state).
 * Call `<key>Reset()` to reset the values back to their original.
 * Call `<key>Apply()` to call `<key>Patch` with all changes passed to `<key>Set`.
 *
 * NOTE: Because changes are in React state, calling Set({x: y}) and then
 * Apply() right after will not commit the changes
 * (React hasn't committed the change yet).
 * You should call Set({x: y}) to put the change into memory
 * (so it's visible in the UI)
 * and then Apply({x: y}) so it's sent to the server.
 */
export function useApiCallMutable(key, caller, options) {
  const result = useApiCall(key, caller, options);
  const update = _.get(options, "update");
  const inProgressPromiseQueue = useRefFn(() => new PromiseQueue());

  function callPatch(params) {
    const updateParams = { ...params };
    if (_.isNumber(result[key].id)) {
      updateParams.id = result[key].id;
    }
    if (_.isEmpty(params)) {
      return Promise.resolve(result[key]);
    }
    hookLogger.info("saving", params);
    return result[`_${key}HandleUpdate`](update(updateParams));
  }

  function patch(params) {
    return inProgressPromiseQueue.current.enqueue(() => callPatch(params));
  }

  // A common pattern is to use <key>Set to make pending changes,
  // then use <key>Apply() to commit those changes,
  // without waiting on the result to proceed in the flow.
  //
  // We need to wait until the commit returns
  // before updating the underlying data (like in case the update fails),
  // and we also need to clear out the previously-pending changes
  // once we update the underlying data.
  //
  // But what happens if a new pending change comes in while the commit is in progress?
  //
  // We store a queue of 'inflight' changes that are part of each commit (patch).
  // Each time we commit pending changes, we copy them into the inflight queue,
  // and then clear the pending changes.
  // All inflight changes are applied over the original data (oldest to newest),
  // and pending changes applied over that.
  //
  // Any new changes that come in while the commit is in progress gets put into
  // the pending changes (which would be part of the next commit).
  // Because of the order we apply data, we should always see an update-to-date version.
  //
  // NOTE WELL: We commit changes one at a time and in order,
  // so we never have to worry about out-of-order commit settling.
  //
  // If the commit resolves, we update the underlying data and
  // throw out the inflight changes for that commit.
  //
  // If the commit rejects, we merge the inflight changes for the (failed) commit
  // back under the pending changes, so it is in the next commit.
  // NOTE: In the case of errors, there is no good way to handle them, really.
  // When we put them back into the pending changes,
  // the change will now appear on top of other inflight changes,
  // and it won't be cleared out if those changes commit.
  // There's not an obvious solution here that doesn't introduce significant design
  // considerations, as the problem is one of UX, not technical.
  //
  // For now we just assume errors are rare,
  // as are concurrent updates to the same resource.
  const [pendingChanges, setPendingChanges] = React.useState({});

  function set(params) {
    setPendingChanges(mergeDeep(pendingChanges, params));
  }

  // We need to use a ref, not state, because all invocations need to keep track
  // of the same queue. This also means we need to use the unique id state hook
  // to cause a rerender when the ref changes.
  const inflightChangesQueueRef = React.useRef([]);
  const [uniqueId, cycleUniqueId] = useUniqueId();

  function debugInflight() {}
  // function debugInflight(action) {
  //   hookLogger
  //     .context({ key, inflight: inflightChangesQueueRef.current })
  //     .debug(`inflight_${action}`);
  // }

  function apply(moreChanges) {
    const changesToPatch = pendingChanges;
    const fullChangesToPatch = { ...changesToPatch, ...moreChanges };
    // If there are no changes, just noop. This is the same as in patch,
    // but it avoids a lot of extra logging and function calls if we early-out here.
    if (_.isEmpty(fullChangesToPatch)) {
      return Promise.resolve(result[key]);
    }

    const changeId = uniqueId;
    cycleUniqueId();
    inflightChangesQueueRef.current.push({ changeId, data: changesToPatch });
    setPendingChanges({});
    debugInflight("before_call");
    return patch(fullChangesToPatch)
      .tap(() => {
        _.remove(inflightChangesQueueRef.current, (data) => data.changeId === changeId);
        cycleUniqueId();
        debugInflight("after_call");
      })
      .tapCatch(() => {
        _.remove(inflightChangesQueueRef.current, (data) => data.changeId === changeId);
        cycleUniqueId();
        setPendingChanges({ ...changesToPatch, ...pendingChanges });
      });
  }

  debugInflight("rendering");
  return {
    ...result,
    [key]: mergeDeep(
      result[key],
      ..._.map(inflightChangesQueueRef.current, "data"),
      pendingChanges
    ),
    [`${key}Patch`]: patch,
    [`${key}Set`]: set,
    [`${key}Reset`]: () => setPendingChanges({}),
    [`${key}Apply`]: apply,
    [`${key}Enqueue`]: (cb) => inProgressPromiseQueue.current.enqueue(cb),
  };
}

export function useApiCallCollection(key, caller, options) {
  const initial = _.get(options, "initial") || [];
  return useApiCallImpl(key, caller, initial, (d) => d.items, options);
}

function useApiCallImpl(key, caller, initial, dataXform, options) {
  const { onFetch, allowUnauthed, memcache, cycleUniqueId, deps } = options || {};
  const { authState } = useUser();
  const apiCache = useApiCache();
  const memoryCache = useMemoryCache();

  const [data, setDataInner] = React.useState(
    (memcache && memoryCache.get(key)) || initial
  );
  const [uniqueId, doCycleUniqueId] = useUniqueId();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  function setData(d) {
    const previousData = data;
    setDataInner(d);
    if (memcache) {
      memoryCache.set(key, d);
    }
    if (cycleUniqueId === "fetch") {
      doCycleUniqueId();
    } else if (cycleUniqueId === "change") {
      if (!_.isEqual(previousData, d)) {
        doCycleUniqueId();
      }
    } else if (cycleUniqueId === "etag") {
      if (previousData.etag !== d.etag) {
        doCycleUniqueId();
      }
    }
    setError(false);
    setLoading(false);
    if (onFetch) {
      onFetch(d);
    }
  }

  function handleUpdate(promise) {
    setLoading(true);
    return promise
      .then(api.pickData)
      .then((d) => {
        setData(dataXform(d));
        return d || null;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        return Promise.reject(e);
      });
  }

  function bustCache() {
    return handleUpdate(caller());
  }

  React.useEffect(() => {
    apiCache.registerBuster(key, bustCache);
    if (authState === AuthState.Loading) {
      return;
    }
    if (allowUnauthed && authState === AuthState.SignedOut) {
      handleUpdate(caller());
    } else if (authState !== AuthState.SignedOut) {
      handleUpdate(caller());
    }
    // eslint-disable-next-line
  }, [authState, ...(deps || [])]);

  return {
    [key]: data,
    [`${key}Replace`]: setData,
    [`_${key}HandleUpdate`]: handleUpdate,
    [`${key}Loading`]: loading,
    [`${key}Error`]: error,
    [`${key}Bust`]: bustCache,
    [`${key}UniqueId`]: uniqueId,
  };
}
